<template>
<b-overlay :show="loading">
  <section>
    <div>
      <div class="mb-2">
        <h2>Personal Information</h2>
      </div>
      <div>
        <b-card>
          <div class="card-body">
            <validation-observer ref="newUserForm" #default="{invalid}">
              <b-form ref="form" @submit.prevent="onUpdateUser">

                <div class="mb-2">
                  <b-media class="text-center">
                    <b-avatar ref="previewEl" badge-variant="transparent" :src="avatarPath" :text="avatarText(`${currentUser.first_name} ${currentUser.last_name}`)" :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(currentUser, 'status')))}`" size="10rem">
                      <template #badge>
                        <b-button variant="primary" class="btn-icon rounded-circle" @click="$refs.avatar.$el.childNodes[0].click()">
                          <feather-icon icon="CameraIcon" />
                        </b-button>
                      </template>
                    </b-avatar>
                  </b-media>

                  <div>
                    <b-form-file v-show="false" ref="avatar" accept="image/*" placeholder="" no-drop @input="onImageRenderer" />
                  </div>
                </div>

                <!--first name -->
                <div class="mb-1">
                  <b-form-group label="First Name" label-for="firstname">
                    <validation-provider #default="{ errors }" name="First Name" vid="firstname" rules="required">
                      <div class="exxtra-input">
                        <span class="leading">
                          <Person />
                        </span>
                        <input id="firstname" v-model="currentUser.first_name" autocomplete="false" readonly>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>

                <!--last name -->
                <div class="mb-1">
                  <b-form-group label="Last Name" label-for="lastname">
                    <validation-provider #default="{ errors }" name="Last Name" vid="lastname" rules="required">
                      <div class="exxtra-input">
                        <span class="leading">
                          <Person />
                        </span>
                        <input id="lastname" v-model="currentUser.last_name" autocomplete="false" readonly>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>

                <!--Email -->
                <div class="mb-1">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
                      <div class="exxtra-input">
                        <span class="leading">
                          <RegistrationMail />
                        </span>
                        <input id="email" v-model="currentUser.email" autocomplete="false" readonly>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
                <!--Phone -->
                <div class="mb-1">
                  <b-form-group label="Phone" label-for="phone">
                    <validation-provider #default="{ errors }" name="Phone" rules="required" vid="phone">
                      <div class="exxtra-input">
                        <span class="leading">
                          <RegistrationPhone />
                        </span>
                        <input id="phone" v-model="currentUser.phone" autocomplete="false">
                        <span v-if="getValueFromSource(currentUser, 'meta.phone_verified', false)" class="trailing">
                          <small class="text-success">verified</small>
                          <feather-icon class="text-success" icon="CheckCircleIcon" />
                        </span>
                        <span v-else class="trailing">
                          <small class="text-danger">pending </small>
                          <feather-icon class="text-danger" icon="AlertCircleIcon" />
                        </span>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>

                <!--Phone -->
                <div class="mb-1">
                  <b-form-group label="Date Of Birth" label-for="phone">
                    <validation-provider #default="{ errors }" name="date_of_birth" rules="required" vid="date_of_birth">
                      <div class="exxtra-input">
                        <span class="leading">
                          <feather-icon icon="CalendarIcon" />
                        </span>
                        <input id="date_of_birth" v-model="currentUser.date_of_birth" autocomplete="false" readonly>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>

                <!--Residential Address -->
                <div class="mb-1">
                  <b-form-group label="Residential Address" label-for="address">
                    <validation-provider #default="{ errors }" name="address" rules="required" vid="address">
                      <div class="exxtra-input">
                        <span class="leading">
                          <ResidentialIcon />
                        </span>
                        <input id="address" v-model="currentUser.residential_address" autocomplete="false" readonly>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>

                <div class="mb-2">
                  <b-form-group label="Marital Status" label-for="marital_status">
                    <validation-provider #default="{ errors }" name="marital_status" rules="required" vid="marital_status">
                      <div class="exxtra-input">
                        <span class="leading">
                          <Person />
                        </span>
                        <b-form-select
                          id="marital_status"
                          v-model="currentUser.marital_status"
                          :options="maritalStatusOptions"
                          style="height: 3.3rem"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div>
                  <b-button class="py-1" type="submit" variant="primary" block :disabled="invalid">
                    Update Profile
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </div>

        </b-card>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import {
  cloneDeep,
  get
} from "lodash";
import Cleave from 'vue-cleave-component';
import ResidentialIcon from '@/assets/svg/ResidentialIcon.svg';
import {
  MUTATE_USER_DATA
} from "@/store/config/mutation-keys";
import RegistrationMail from '@/assets/svg/RegistrationMail.svg';
import RegistrationPhone from '@/assets/svg/RegistrationPhone.svg';
import Person from '@/assets/svg/Person.svg';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BCardTitle,
  BMedia,
  BAvatar,
  BButton,
  BFormFile,
  BFormGroup,
  BForm,
  BOverlay,
  BFormSelect
} from "bootstrap-vue";

export default {
  name: "Update",
  components: {
    BCard,
    BForm,
    BMedia,
    Cleave,
    Person,
    BAvatar,
    BButton,
    BOverlay,
    BFormFile,
    BCardTitle,
    BFormGroup,
    BFormSelect,
    ResidentialIcon,
    RegistrationMail,
    RegistrationPhone,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      avatarPath: '',
      cleaveDateOptions: {
        date: true,
        delimiter: '/',
        datePattern: ['Y', 'm', 'd'],
        blocks: [4, 2, 2],
        delimiters: ['-', '-', '-']
      },
      userData: null,
      maritalStatusOptions: [
        {
          value: "single",
          text: "Single"
        },
        {
          value: "married",
          text: "Married"
        },
        {
          value: "divorced",
          text: "Divorced"
        }
      ]
    }
  },
  watch: {
    currentUser: {
      handler() {
        this.avatarPath = this.getValueFromSource(this.currentUser, 'avatar.path', '');
        this.userData = cloneDeep(this.currentUser);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.userData = cloneDeep(this.currentUser)
    this.mfa_conf = this.userData.mfa_conf;
  },
  methods: {
    async onUpdateUser() {
      try {
        this.loading = true;

        const formData = new FormData();

        formData.append('date_of_birth', this.getValueFromSource(this.userData, 'date_of_birth', ''));
        formData.append('marital_status', this.getValueFromSource(this.userData, 'marital_status', ''));
        formData.append('residential_address', this.getValueFromSource(this.userData, 'residential_address', ''));

        if (this.updatedImage) {
          formData.append("avatar", this.updatedImage);
        }

        const response = await this.useJwt().authService.updateProfile(formData);
        const udpated_user = get(response, 'data.data');
        this.$store.commit(`auth/${MUTATE_USER_DATA}`, udpated_user);

        if (this.updatedImage) {
          this.updatedImage = null;
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Profile has been updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.avatarPath = reader.result
          this.updatedImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  }
}
</script>

<style scoped>

</style>
