var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('section',[_c('div',[_c('div',{staticClass:"mb-2"},[_c('h2',[_vm._v("Personal Information")])]),_c('div',[_c('b-card',[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"newUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdateUser($event)}}},[_c('div',{staticClass:"mb-2"},[_c('b-media',{staticClass:"text-center"},[_c('b-avatar',{ref:"previewEl",attrs:{"badge-variant":"transparent","src":_vm.avatarPath,"text":_vm.avatarText(((_vm.currentUser.first_name) + " " + (_vm.currentUser.last_name))),"variant":("light-" + (_vm.resolveStatusVariant(_vm.getLoanStatusTextForClient(_vm.getValueFromSource(_vm.currentUser, 'status'))))),"size":"10rem"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.avatar.$el.childNodes[0].click()}}},[_c('feather-icon',{attrs:{"icon":"CameraIcon"}})],1)]},proxy:true}],null,true)})],1),_c('div',[_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"avatar",attrs:{"accept":"image/*","placeholder":"","no-drop":""},on:{"input":_vm.onImageRenderer}})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('Person')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.first_name),expression:"currentUser.first_name"}],attrs:{"id":"firstname","autocomplete":"false","readonly":""},domProps:{"value":(_vm.currentUser.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "first_name", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('Person')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.last_name),expression:"currentUser.last_name"}],attrs:{"id":"lastname","autocomplete":"false","readonly":""},domProps:{"value":(_vm.currentUser.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "last_name", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('RegistrationMail')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.email),expression:"currentUser.email"}],attrs:{"id":"email","autocomplete":"false","readonly":""},domProps:{"value":(_vm.currentUser.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "email", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('RegistrationPhone')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.phone),expression:"currentUser.phone"}],attrs:{"id":"phone","autocomplete":"false"},domProps:{"value":(_vm.currentUser.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "phone", $event.target.value)}}}),(_vm.getValueFromSource(_vm.currentUser, 'meta.phone_verified', false))?_c('span',{staticClass:"trailing"},[_c('small',{staticClass:"text-success"},[_vm._v("verified")]),_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"CheckCircleIcon"}})],1):_c('span',{staticClass:"trailing"},[_c('small',{staticClass:"text-danger"},[_vm._v("pending ")]),_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"AlertCircleIcon"}})],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Date Of Birth","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"date_of_birth","rules":"required","vid":"date_of_birth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.date_of_birth),expression:"currentUser.date_of_birth"}],attrs:{"id":"date_of_birth","autocomplete":"false","readonly":""},domProps:{"value":(_vm.currentUser.date_of_birth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "date_of_birth", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Residential Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('ResidentialIcon')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentUser.residential_address),expression:"currentUser.residential_address"}],attrs:{"id":"address","autocomplete":"false","readonly":""},domProps:{"value":(_vm.currentUser.residential_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentUser, "residential_address", $event.target.value)}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Marital Status","label-for":"marital_status"}},[_c('validation-provider',{attrs:{"name":"marital_status","rules":"required","vid":"marital_status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('Person')],1),_c('b-form-select',{staticStyle:{"height":"3.3rem"},attrs:{"id":"marital_status","options":_vm.maritalStatusOptions},model:{value:(_vm.currentUser.marital_status),callback:function ($$v) {_vm.$set(_vm.currentUser, "marital_status", $$v)},expression:"currentUser.marital_status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Update Profile ")])],1)])]}}])})],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }