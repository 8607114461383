<template>
  <section>
    <div>
      <div class="mb-1">
        <b-card-title>
          <h2>Referral Link</h2>
        </b-card-title>
      </div>
      <b-alert
        variant="danger"
        show
      >
        <div v-if="!referralsEnabled" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small style="margin-left: 5px;">Referrals are disabled at the moment. You won't earn any commission on new users you refer to our platform.</small>
          </b-card-text>
        </div>
      </b-alert>
      <b-card>
        <div class="d-flex justify-content-between align-items-center">
          <div>{{ generateReferralLink() }}</div>
          <div @click="onCopyReferralLink()">
            <feather-icon class="cursor-pointer text-right ml-1" icon="CopyIcon" />
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BCard, BCardTitle, BAlert, BCardText } from "bootstrap-vue";

export default {
  name: "ReferralLink",
  components: {
    BCard,
    BCardTitle,
    BAlert,
    BCardText
  },
  data() {
    return {
      active_status: false,
    };
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    referralsEnabled() {
      return this.getValueFromSource(this.settings, "general_settings.enable_referrals", false)
    }
  },
  methods: {
    onCopyReferralLink() {
      navigator.clipboard
        .writeText(this.generateReferralLink())
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "API key copied",
              icon: "CheckCircleIcon",
              variant: "success",
              text: "API Key copied successfully.",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: "There was an error copying api_key. Please copy manually",
            },
          });
        });
    },
  },
};
</script>
